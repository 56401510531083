
import Vue, { PropType } from 'vue';

import TTAvatar from '../uikit/components/TTAvatar/TTAvatar.vue';

interface Company {
  // Название компании
  name: string;
  isAdmin?: boolean;
  id?: string;
}

interface Action {
  // событие которое будет сгенерировано при клике на пункт меню
  id: string;
  // иконка слева от пункта меню
  icon:string ;
  // текст пункта меню
  text:string;
  // текст пункта меню
  color?:string
}

export default Vue.extend({
  name: 'AppProfileMenu',
  components: { TTAvatar },
  props: {
    width: {
      type: Number,
      default: 320,
    },
    userName: {
      type: String,
      required: true,
    },
    userInitials: {
      type: String,
      default: null,
    },
    actionsIconSize: {
      type: [Number, String],
      default: 19,
    },
    userAvatar: {
      type: String,
      default: null,
    },

    companies: {
      type: Array as PropType<Company[]>,
      default: () => [] as Company[],
    },

    currentCompany: {
      type: Object as PropType<Company>,
      default: null,
    },
    companyAvatar: {
      type: String,
      default: null,
    },
    showHeader: {
      type: Boolean,
      default: false,
    },

    actions: {
      type: Array as PropType<Action[]>,
      default: () => [] as Action[],
    },
    profileMenu: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      profileMenuLocal: false,
    };
  },
  computed: {
    showProfileMenu(): boolean {
      return this.profileMenu || this.profileMenuLocal;
    },
  },
  methods: {
    toggleProfileMenu(event: boolean) {
      if (event === this.profileMenuLocal) return;
      this.profileMenuLocal = event;
      this.$emit('input', event);
    },
  },
});
