// eslint-disable-next-line import/prefer-default-export
export const LEARNING_APPS_LIST = [
  'assessment',
  'catalogue',
  'expert_assessment',
  'lxp',
  'lxp-assignment',
  'lxp-multi-level-test',
  'multi_content',
  'multi_level_test',
  'skilldev',
  'tracks',
  'tt_course',
];
