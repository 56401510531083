<template>
  <AppNavbar
    :current-app="currentApp"
    :items="myApps"
    brand-url="/"
    :logo-url="engagementSurveysUrl"
  >
    <template #company>
      <TTBtn
        v-if="isAdmin"
        text
        :depressed="false"
        color="tt-light-mono-100"
        :href="profileAdminLink"
        data-test="tt-module-menu-btn"
        data-test-value="admin"
        data-test-label="admin"
        height="46"
        class="admin-link tt-text-caption font-weight-regular px-0 mr-10 custom-item"
      >
        <VIcon class="mr-2">
          fal fa-cog
        </VIcon>
        {{ $t("adminPanel") }}
      </TTBtn>
      <AppProfileMenu
        :user-name="fio"
        :user-avatar="avatarUrl"
        :user-initials="avatarText"
        :current-company="myCompany"
        :companies="companies"
        :actions="computedActions"
        show-header
        @my-data="handleMyData"
        @my-team="handleMyTeam"
        @sign-out="handleSignOut"
        @select-company="selectCompany($event)"
      />
    </template>
  </AppNavbar>
</template>

<script>
import { mapState } from 'vuex';
import AppNavbar from '@widgets/AppNavbar.vue';
import AppProfileMenu from '@widgets/AppProfileMenu.vue';
import { getDomainForCookies } from '@/helpers/url';
import { getImageUrl } from '@/helpers/getImageUrl';
import { accessToken, companyId } from '@/services/cookies';
import { LEARNING_APPS_LIST } from '@/constants';

const {
  VUE_APP_ARCHIVIST_ADDRESS,
  VUE_APP_GOALS_ADDRESS,
  VUE_APP_LEARNINGBOX_ADDRESS,
  VUE_APP_ENGAGEMENTADMIN_ADDRESS,
  VUE_APP_ONBOARDING_ADDRESS,
  VUE_APP_GATEKEEPER_ADDRESS,
  VUE_APP_PROFILEADMIN_ADDRESS,
} = process.env;

export default {
  name: 'AppNav',
  components: { AppNavbar, AppProfileMenu },
  props: {
    drawer: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      apps: [],
      currentApp: VUE_APP_ENGAGEMENTADMIN_ADDRESS,
      profileAdminLink: VUE_APP_PROFILEADMIN_ADDRESS,
      actions: [
        {
          id: 'my-data',
          icon: 'fal fa-clipboard-user',
          text: this.$t('authorization.myData'),
          testValue: 'my-data',
        },
        {
          id: 'my-team',
          icon: '$usersLight',
          text: this.$t('authorization.myTeam'),
          testValue: 'my-team',
        },
        {
          id: 'sign-out',
          icon: '$signOut',
          text: this.$t('authorization.signOut'),
          color: 'red',
        },
      ],
    };
  },

  computed: {
    isHideTeam() {
      return this.chiefs.length === 0 && this.teams.length === 0;
    },
    computedActions() {
      if (this.isHideTeam) {
        return this.actions.filter(({ id }) => id !== 'my-team');
      }
      return this.actions;
    },
    myApps() {
      const apps = [
        {
          text: this.$t('application.goals'),
          href: VUE_APP_GOALS_ADDRESS,
          testValue: 'goals',
          license: ['pms'],
        },
        {
          text: this.$t('application.learning'),
          href: VUE_APP_LEARNINGBOX_ADDRESS,
          testValue: 'learning',
          license: LEARNING_APPS_LIST,
        },
        {
          text: this.$t('application.surveys'),
          href: VUE_APP_ENGAGEMENTADMIN_ADDRESS,
          testValue: 'engagement',
          license: ['engagement', 'tsxass'],
        },
        {
          text: this.$t('application.onboarding'),
          href: VUE_APP_ONBOARDING_ADDRESS,
          testValue: 'snami',
          license: ['snami'],
        },
      ];
      return apps.filter(
        (app) => !!(app.license && app.license.some((appLicense) => this.licenses.includes(appLicense))),
      );
    },
    myCompany() {
      const imageMeta = {
        uuid: this.company.id,
        type: 'company',
        name: 'logo',
      };
      const logo = getImageUrl(imageMeta) || '';
      return {
        logo,
        name: this.company.name,
        id: this.company.id,
        subtitle: this.$t('authorization.myCompany'),
      };
    },
    myProfile() {
      const imageMeta = {
        uuid: this.company.id,
        type: 'company',
        name: 'logo',
      };
      const logo = getImageUrl(imageMeta) || '';
      return {
        logo,
        name: this.company.name,
        id: this.company.id,
        subtitle: this.$t('authorization.myCompany'),
      };
    },
    avatarUrl() {
      const token = accessToken.get().split('.');
      const { sub: uuid } = JSON.parse(atob(token[1]));
      const type = 'user';
      const name = 'avatar';
      return getImageUrl({
        uuid,
        type,
        name,
      });
    },
    fio() {
      if (!this.profile) {
        return '';
      }
      const { name } = this.profile;
      return [name.last, name.first, name.middle].filter(Boolean).join(' ');
    },
    avatarText() {
      if (!this.profile) {
        return null;
      }
      const { name } = this.profile;
      if (name?.first && name?.last) {
        return `${name.first[0]}${name.last[0]}`;
      }
      return null;
    },
    ...mapState('profile', {
      profile: 'profile',
      licenses: 'licenses',
      companies: 'companies',
      chiefs: 'chiefs',
      teams: 'teams',
      company(state) {
        return state.company || {
          id: '',
          name: this.$t('authorization.myCompany'),
        };
      },
    }),
    isAdmin() {
      return this.profile?.roles?.find((role) => role.role === 'admin');
    },
    engagementSurveysUrl() {
      return new URL('/app/surveys', VUE_APP_ENGAGEMENTADMIN_ADDRESS).href;
    },
  },
  methods: {
    selectCompany(company) {
      if (company.id === this.myCompany.id) {
        return;
      }
      companyId.set(company.id, { domain: getDomainForCookies() });
      window.location.replace('/');
    },
    handleMyData() {
      const url = new URL('app/profile', VUE_APP_ARCHIVIST_ADDRESS);
      window.location.replace(url.href);
    },
    handleMyTeam() {
      const url = new URL('app/team', VUE_APP_ARCHIVIST_ADDRESS);
      window.location.replace(url.href);
    },
    handleSignOut() {
      accessToken.remove({ domain: getDomainForCookies() });
      companyId.remove({ domain: getDomainForCookies() });
      // IMPORTANT: редиректим в гейткипер с сылкой на текущую страницу
      const url = new URL(`/?back=${window.location.href}`, VUE_APP_GATEKEEPER_ADDRESS);
      window.location.replace(url.href);
    },
  },
};
</script>
